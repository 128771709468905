const mainPageNavigation = [
    { id: 1, icon: 'menu_book', name: 'Reading',  path: 'student.ptePractice.collapse', query: 'reading' },
    { id: 2, icon: 'create', name: 'Writing', path: 'student.ptePractice.collapse', query: 'writing' },
    { id: 3, icon: 'psychology', name: 'Speaking', path: 'student.ptePractice.collapse', query: 'speaking' },
    { id: 4, icon: 'hearing', name: 'Listening', path: 'student.ptePractice.collapse', query: 'listening' },
    { id: 5, icon: 'description', name: 'Quiz Question', path: 'student.ptePractice.collapse', query: 'description' },
    { id: 6, icon: 'layers', name: 'Vocabulary', path: 'student.ptePractice.collapse', query: 'vocabulary' }
];


const readingPageNavigation = [
    { 
        id: 1,
        name: 'Reading',
        slug: 'reading',
        test: 15,
        bgColor: '#4cb050',
        children: [
            {id: 1, name: 'Reading PTE 01'},
            {id: 2, name: 'Reading PTE 02'},
            {id: 3, name: 'Reading PTE 03'},
            {id: 4, name: 'Reading PTE 04'},
        ]
    },
    { 
        id: 2,
        name: 'Writing',
        slug: 'writing',
        test: 25,
        bgColor: '#066bb9',
        children: [
            {id: 1, name: 'Writing PTE 01'},
            {id: 2, name: 'Writing PTE 02'},
            {id: 3, name: 'Writing PTE 03'},
            {id: 4, name: 'Writing PTE 04'},
        ]
    },
    { 
        id: 3,
        name: 'Speaking',
        slug: 'speaking',
        test: 15,
        bgColor: '#d4620c',
        children: [
            {id: 1, name: 'Speaking PTE 01'},
            {id: 2, name: 'Speaking PTE 02'},
            {id: 3, name: 'Speaking PTE 03'},
            {id: 4, name: 'Speaking PTE 04'},
        ]
    },
    { 
        id: 4,
        name: 'Listening',
        slug: 'listening',
        test: 15,
        bgColor: '#903578',
        children: [
            {id: 1, name: 'Listening PTE 01', },
            {id: 2, name: 'Listening PTE 02', },
            {id: 3, name: 'Listening PTE 03', },
            {id: 4, name: 'Listening PTE 04', },
        ]
    },

];

const questions = [];

for(let i = 1; i <= 15; i++) {
    questions.push({
        id: i, code: 'Q125', title: 'Population of German'
    });
}

export default {
    mainPageNavigation,
    readingPageNavigation,
    questions
}