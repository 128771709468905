<template>
  <div>
    <div class="flex back-blue text-white p-6 rounded align-center">
      <md-icon class="m-0 text-white text-3xl">help</md-icon>
      <h3 class="text-uppercase ml-3 text-2xl font-bold">Quiz Test</h3>
          <md-button v-if="layoutMeta == 'teacher' || layoutMeta == 'master'"
            @click="CreateQuiz"
            class="bg-green text-white rounded-md md-dense m-0 text-uppercase ml-2"   
          >
            <md-icon  class="text-white">add</md-icon> Create Quiz Test
          </md-button>
    </div>
    <QuizSetList
      :child-items="getQuizQuestion"
      :active-item="type"
      @on-navigate="onNavigate"
    />
  </div>
</template>

<script>
import QuizSetList from "@/views/teacher/test-creation/quiz-test/QuizSetList.vue";
import data from '@/data/pte-practice/data';
import { mapActions, mapGetters,mapMutations } from 'vuex';
import { slugify } from '@/utils';
import { query } from "@/utils/queryString";

export default {
  props: {
    layout: {
      type: String,
      default: 'default'
    }
  },
  data() {
      return {
          layoutMeta: 'student',
          items: data.readingPageNavigation,
          selectedValue: ''
      }
  },
  computed: {
    ...mapGetters({
      getQuizQuestion: "quizCreation/getPTEPracticeQuestion",
      meta: "quizCreation/getQuizListMeta",
    }),
    type() {
      return this.$route.query.type
    },
    userId() {
      return localStorage.getItem('user_id');
    },
  },
  components: {
    QuizSetList
  },
  methods: {
    ...mapActions({
      actQuizQuestion: "quizCreation/actQuizQuestion",
      actTestSections: "testCreation/actTestSections",
      actExamEnroll: "examination/actExamEnroll",
    }),
    ...mapMutations({
      setCartClear: "quizCreation/setCartClear",
     }),
    CreateQuiz(){
      localStorage.removeItem("test");
      localStorage.removeItem("testType");
      localStorage.removeItem("quizQuestionCount");
      localStorage.removeItem("selectedSections");
      localStorage.removeItem("createTypeResponse");
      localStorage.removeItem("selectedSectionsUpdated");
      this.setCartClear();
      this.$router.push({
        name: this.layout === 'master' ? 'master-quizCreation.create' : 'quizCreation.create',
        query: {
          type: 'quiz-test'
        }
      });
    },
    onNavigate({child}) {
      if(this.layoutMeta=='student'){
        this.$router.push({
          name: 'student.ptePracticePreview',
          params: {
            slug: slugify(child.name),
            id: child.t_id
          }
        });
      } else {
        this.$router.push({
          name: this.layout === 'master' ? 'trainer.ptePracticePreview' : 'teacher.ptePracticePreview',
          params: {
            slug: slugify(child.name),
            id: child.t_id
          }
        });
      }
      localStorage.setItem("pte_id",  child.id);
      this.$root.id = child.id;
      this.$root.name = child.name;
    },
    onStart(value) {
      //console.log(value,'selectedValue')
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actQuizQuestion(`?${query.get()}`);
    },
    onEnd(value) {
      
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      console.log(query.get(),'selectedValue')
      this.actQuizQuestion(`?${query.get()}`);
    },
  },
  mounted() {
    this.layoutMeta = this.$route.meta.layout;    
    this.actQuizQuestion(``);
  }
};
</script>