<template>
  <div>
      <div class="panel mt-6 p-3">
        <div class="pb-2 panel-body p-3">
          <div class="mb-4 flex justify-between flex-wrap justify-xs-center">
            <div class="flex items-center">
              <SelectComponent
                :items="this.item_quizzes"
                :placeholder="'Select Quizzes'"
                v-model="this.selectQuizzes"
                return-type="object"
                @getObject="getObject"
                class="w-40 m-1" 
              />
              <SelectComponent
                :items="this.StatusType"
                return-type="object"
                :placeholder="'Select Status'"
                @getObject="getStatus"
                v-model.trim="selectedStatus"
                class="w-32 m-1" 
              />
            </div>
            <SearchBox
              placeholder="Search"
              class="w-74 p-2 m-1"
              v-model="keyword"
              result-key="branchName"
              @submit="submitSearchKeyword"
            />
          </div>
        </div>
        <Table :items="getQuizQuestion" @onSelect="onSelect" hover-action>
          <template
            slot="row"
            slot-scope="{ item }"
          >
            <md-table-cell md-sort-by="Quiz_id" md-label="Q_ID" class="font-bold">{{ item.Quiz_id }}</md-table-cell>
            <md-table-cell md-sort-by="title" md-label="QUESTION TITLE" class="font-bold">{{ item.name }}</md-table-cell>
            <md-table-cell md-label="NO OF QUESTION" class="pl-4 font-bold" >{{ item.total_quiz_no}}</md-table-cell>
            <md-table-cell md-label="QUIZ TYPE" class="font-bold">{{ item.template_type }}</md-table-cell>
            <md-table-cell md-label="Created At" class="font-bold">{{ item.created_at }}</md-table-cell>
            <md-table-cell md-label="Created By" class="text-algal font-bold">{{ item.created_by }}</md-table-cell>

            <md-table-cell md-label="STATUS">
             <span 
             class="font-bold" 
             :class="item.status !== 1 ? 'text-gray-700' : 'text-success' ">
              {{item.status !== 1 ? 'Draft' : 'Published'}} 
            </span>
            
            </md-table-cell>
            <md-table-cell>
              <span class="action flex">
                <md-icon class="bg-link-water text-gray-700 rounded-full p-2 text-sm m-1">visibility</md-icon>
              </span>
            </md-table-cell>
          </template>
        </Table>
        <div style="min-height: 200px" v-if="isLoading">
          <Spinner :loading="isLoading" :diameter="40" :stroke="3" />
        </div>
        <div class="flex justify-end align-center mt-8">
          <Paginate
              :start="meta.from"
              :end="meta.to"
              :next="meta.current_page"
              :prev="meta.last_page"
              :total="meta.total"
              :limit="meta.per_page"
              @on-start="onStart"
              @on-end="onEnd"
          />
        </div>
        <Dialog>
          <component :is="component" :Quiz_item="this.Quiz_item"></component>
        </Dialog>
    </div>
  </div>
</template>

<script>
import { SearchBox, SelectComponent ,Table, Dialog, Paginate, Spinner } from "@/components";
import QuizAlert from "@/views/student/quiz-test/TeacherAlert.vue";
import data from "@/data/quiz-test/data";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { query } from "@/utils/queryString";

export default {
  components: {
    Table,
    SearchBox,
    SelectComponent,
    Dialog,
    QuizAlert,
    Paginate,
    Spinner
  },
  data() {
    return {
      quizzes: data.quizzes,
      amount: 10,
      Quiz_item: null,
      keyword: '',
      component: 'QuizAlert',
      selectQuizzes: null,
      item_quizzes: [
        { id: "all", name: "ALL QUIZZES" },
        { id: 'ALL_IN_ONE', name: "ALL IN ONE" },
        { id: 'ONE_BY_ONE', name: "ONE BY ONE" },
      ],
      selectedStatus: null,
      StatusType: [
        { id: 2, name: "All" },
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
      isLoading: false
    };
  },
  props: {
      activeItem: {
      type: String,
      },
      childItems: {
      type: Array
      }
  },
  watch: {
    keyword(oldvalue, newValue) {
        query.set('search', oldvalue)
        // For reseting search result
        if(oldvalue.length < newValue.length && oldvalue.length == 0) {
          this.isLoading = true
          this.actQuizQuestion(`?${query.get()}`)
          .finally(() => {
            this.isLoading = false
          });
        }
        // For submitting search api after 3 cherecter
        if(oldvalue.length >= 3) {
          this.isLoading = true
          this.actQuizQuestion(`?${query.get()}`)
          .finally(() => {
            this.isLoading = false
          });
        }
    }
  },
  computed: {
    ...mapGetters({
      getQuizs: "quiz/getQuizs",
      getQuizSetId: "quiz/getQuizSetId",
      getQuizData: "quiz/getQuizData",
      meta: "quizCreation/getQuizListMeta",
      getQuizQuestion: "quizCreation/getPTEPracticeQuestion",
    })
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    ...mapActions({
      actQuizSetList: "quiz/actQuizSetList",
      actShowQuizs: "quiz/actShowQuizs",
      actGetQuizSetId: "quiz/actGetQuizSetId",
      actQuizQuestion: "quizCreation/actQuizQuestion",
    }),
    submitSearchKeyword() {
      this.isLoading = true
      this.actQuizQuestion(`?${query.get('search', this.keyword)}`)
      .finally(() => {
        this.isLoading = false
      });
    },
    getStatus(item) {
      this.selectedStatus = item.id;
      this.selectedStatus === 2 ? query.remove('active') : query.set('active', this.selectedStatus);
      query.set('page', 1) 
      this.isLoading = true
      this.actQuizQuestion(`?${query.get()}`)
      .finally(() => {
        this.isLoading = false
      });
    },
    getObject(item){
      this.selectQuizzes = item.id;
      this.selectQuizzes === 'all' ? query.remove('template_type') : query.set('template_type', this.selectQuizzes);
      query.set('page', 1) 
      this.isLoading = true
      this.actQuizQuestion(`?${query.get()}`)
      .finally(() => {
        this.isLoading = false
      });
    },
    onSelect(item) {
      if(item){
        this.component = 'QuizAlert';
        this.dialog(true);
        this.Quiz_item = item;
      }
    },
    onStart(value) {
      this.dialog(false);
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.isLoading = true
      this.actQuizQuestion(`?${query.get()}`)
      .finally(() => {
        this.isLoading = false
      });
    },
    onEnd(value) {
      this.dialog(false);
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.isLoading = true
      this.actQuizQuestion(`?${query.get()}`)
      .finally(() => {
        this.isLoading = false
      });
    },
  },
  created() {
    this.dialog(false);
    this.isLoading = true
    this.actQuizQuestion(``)
    .finally(() => {
      this.isLoading = false
    });
  },
};
</script>
