<template>
  <div class="mt-6 relative text-center">
    <div style="min-height: 100px;" v-if="loading">
      <Spinner :loading="loading" :diameter="40" :stroke="3" />
    </div>
    <span v-if="childItems.length === 0 && !loading">No Data</span>
    <div>
      <div class="collapse_anim">
        <div v-for="child in childItems"
              :key="child.id" class="mb-4">
          <template v-if="child.id != null">
            <div
              class="p-4 my-2 text-gray-900 rounded bg-link-water flex justify-between pointer align-center"
              @click="$emit('on-navigate', {child}),toggleAction(child)"
            >
            <div class="flex align-center">
              <p class="text-uppercase text-xl font-semibold pr-16 text-blue">
                {{ child.t_id }}
              </p>
              <p class="text-uppercase text-xl font-bold">
                {{ child.name }}
              </p>
              <p v-if="child.id !== test_id" class="text-uppercase font-semibold ml-4 br-4 p-0_5 text-white back-blue">
                {{ child.created_at[0]}} {{ child.created_at[1]}} {{ child.created_at[2]}}
              </p>
              <p v-if="child.id == test_id" class="text-uppercase font-semibold ml-4 p-0_5 br-4 text-white back-green">
                Running
              </p>
            </div>
              <div>
                <md-icon class="bg-periwinkle-gray text-black p-4 rounded-full"
                  >chevron_right</md-icon
                >
              </div>
            </div>
          </template>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
import { mapActions} from 'vuex';
import { Spinner } from "@/components";
export default {
  components: {
    Spinner
  },
  data() {
    return {
      active: 0,
      test_id: localStorage.getItem('pte_id'),
      StatusTrue: false,
      StatusFalse: true
    };
  },
  props: {
    activeItem: {
      type: String,
    },
    childItems: {
      type: Array
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  mounted(){
    // console.log("dd",this.childItems);
  },
  computed: {
    type() {
      return this.$route.query.type
    }

  },
  methods: {
    ...mapActions({
      actSectionQuestionTypes: "testCreation/actSectionQuestionTypes",
      actTestDetails: "testCreation/actTestDetails"
    }),
    toggleAction(){
      this.$root.showDialog = true;
       localStorage.removeItem('NextQuestionDetect');
       localStorage.setItem('Slideindex', 0)
    },
    toggleAccordion(type) {
      if(this.type == type) return;
      this.$router.push({
       // name: 'student.ptePractice.collapse',
        query: {
          type: type
        }
      });
      this.actSectionQuestionTypes(`?section=${this.type}`);
    },
  },
  created(){
    // console.log(this.childItems.id,this.test_id)
  }
};
</script>

<style>
.collapse_anim{
   transition:transform 0.5s;
   overflow-y: auto
}
</style>
