<template>
  <component :is="component" @dailogHide="dailogHide" :layout="layout"></component>
</template>

<script>
import OneByOne from "@/views/student/quiz-test/quizType/OneByOne.vue";
import AllInOne from "@/views/student/quiz-test/quizType/AllInOne.vue";
import { mapMutations, mapGetters } from "vuex";
export default {
components: {
  OneByOne,
  AllInOne,
},
props:{
  showStudyGuideDialog: Boolean,
  component: {
    default: true
  },
  layout: {
    type: String
  }
},
computed: {
    ...mapGetters({
      getQuestion: "quiz/getQuestion",
    })
},
methods: {
  ...mapMutations({
    dialog: "setShowDialog",
  }),
  dailogHide(){
    this.showStudyGuideDialog = false;
    this.dialog(false);
  },
},
};
</script>
