<template>
  <div class="md-layout md-gutter">
    <div 
      class="md-layout-item mb-6 md-xlarge-size-25 md-large-size-25 md-medium-size-33 md-small-size-50 md-xsmall-size-100"
      v-for="item in items" :key="item.id"
    >
      <BoxNavigationCard :item="item" />
    </div> 
  </div>
</template>

<script>
import data from '@/data/test-creation/data'
import BoxNavigationCard from "@/components/molecule/BoxNavigationCard";
export default {
  props: {
    layout: {
      type: String,
      default: 'default' 
    }
  },
  computed: {
    items() {
      return this.layout === 'master' ? data.MasterMainNavigation : data.mainNavigation;
    }
  },
  components: {
    BoxNavigationCard,
  },
};
</script>
