<template>
  <div>
    <div class="flex back-blue text-white p-6 rounded align-center">
      <md-icon class="m-0 text-white text-3xl">help</md-icon>
      <h3 class="text-uppercase ml-3 text-2xl font-bold">PTE Practice</h3>
          <md-button v-if="layoutMeta == 'master'"
              class="bg-green text-white rounded-md md-dense m-0 text-uppercase ml-2"
              :to="{name: 'testCreation.create', query: {type: 'pte-practice'}}"
          >
            <md-icon class="text-white">add</md-icon> Create PTE Practice
          </md-button>
    </div>
    <div class="mt-4 mb-2">
      <SearchBox v-model="keyword" result-key="search" @submit="submitSearchKeyword" class="w-64 p-1 pl-3" placeholder="Search Name"/>
    </div>
    <CollapseComponent
      @on-navigate="onNavigate"
      :active-item="type"
      :child-items="getPTEPracticeQuestion"
      :loading="isLoading"
    />
    <div class="flex justify-end align-center mt-8">
      <Paginate
        :start="meta.from"
        :end="meta.to"
        :next="meta.current_page"
        :prev="meta.last_page"
        :total="meta.total"
        :limit="meta.per_page"
        @on-start="onStart"
        @on-end="onEnd"
      />
    </div>
  </div>
</template>

<script>
import { Paginate } from "@/components";
import CollapseComponent from "./CollapseComponentTest.vue";
import data from '@/data/pte-practice/data';
import { mapActions, mapGetters } from 'vuex';
import { slugify } from '@/utils';
import SearchBox from "@/components/atom/SearchBox";
import { query } from "@/utils/queryString";

export default {
  components: {
    CollapseComponent,
    SearchBox,
    Paginate
  },
  data() {
      return {
          layoutMeta: 'student',
          items: data.readingPageNavigation,
          isLoading: false,
          keyword: '',
          query: '?',
      }
  },
  computed: {
    ...mapGetters({
      getPTEPracticeQuestion: "testCreation/getPTEPracticeQuestion",
      meta: "testCreation/GetPTEPracticeQuestionMetaMeta",
    }),
    type() {
      return this.$route.query.type
    },
    userId() {
      return localStorage.getItem('user_id');
    },
  },
  watch: {
    keyword(previous, current) {
        query.set('search', previous)
        if(previous.length < current.length && previous.length == 0) {
          this.isLoading = true
          this.actPTEPracticeQuestion(`?${query.get()}`)
          .finally(() => {
            this.isLoading = false
          });
        }
        if(previous.length >= 3) {
          this.isLoading = true
          this.actPTEPracticeQuestion(`?${query.get()}`)
          .finally(() => {
            this.isLoading = false
          });
        }
    }
  },
  methods: {
    ...mapActions({
      actPTEPracticeQuestion: "testCreation/actPTEPracticeQuestion",
      actTestSections: "testCreation/actTestSections",
      actExamEnroll: "examination/actExamEnroll",
    }),
    onNavigate({child}) {
      if(this.layoutMeta=='student'){
        this.$router.push({
          name: 'student.ptePracticePreview',
          params: {
            slug: slugify(child.name),
            id: child.t_id
          }
        });
      }else{
        this.$router.push({
          name: 'teacher.ptePracticePreview',
          params: {
            slug: slugify(child.name),
            id: child.t_id
          }
        });
      }
      localStorage.setItem("pte_id",  child.id);
      this.$root.id = child.id;
      this.$root.name = child.name;
    },
    submitSearchKeyword() {
      this.keyword ? query.set('search', this.keyword) : query.remove('search'); 
      this.keyword && query.set('page', 1) 
      this.isLoading = true
      this.actPTEPracticeQuestion(`?${query.get()}`)
      .finally(() => {
        this.isLoading = false
      });
    },
    onStart(value) {
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.isLoading = true
      this.actPTEPracticeQuestion(`?${query.get()}`)
      .finally(() => {
        this.isLoading = false
      });
    },
    onEnd(value) {
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.isLoading = true
      this.actPTEPracticeQuestion(`?${query.get()}`)
      .finally(() => {
        this.isLoading = false
      });
    },
  },
  mounted() {
    this.layoutMeta = this.$route.meta.layout;    
    this.isLoading = true
    this.actPTEPracticeQuestion(``)
    .finally(() => {
      this.isLoading = false
    });
  }
};
</script>