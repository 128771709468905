<template>
  <QuestionView :component="this.component" layout="teacher"/>
</template>
<script>
import QuestionView from "@/views/student/quiz-test/TeacherQuestionView.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    QuestionView
  },
  props:{
    Quiz_item: {
      type: [Object,Array],
      default: () => null
    },
  },
  data() {
    return {
      component: null,
      quiz_id: ''
    };
  },
  computed: {
    ...mapGetters({
      getQuestion: "quiz/getQuestion",
    })
  },
  methods:{
    ...mapActions({
      actShowQuizs: "quiz/actShowQuizs",
      actQuizStart: "quiz/actQuizStart"
    }),
    StartQuiz() {
      if(this.Quiz_item.id){
        if(this.Quiz_item.template_type === "ONE_BY_ONE") {
          this.component = 'OneByOne';
        } else {
            this.component = 'AllInOne';
        }
        this.actShowQuizs(this.Quiz_item.id); 
        let data = {
          quiz_set_id: this.Quiz_item.id
        }
        this.actQuizStart(data);
      }
    },
  },
  mounted(){ 
    this.StartQuiz()
  }
}
</script>